<template>
  <Layout>
    <div>
      <loader v-if="loader" />
      <b-row class="pb-4">
        <b-col cols="12">
          <h2>
            {{ $t('payments.manageTed.manageTedTitle') }}
          </h2>
        </b-col>
      </b-row>

      <p class="ted-data">
        Transferências pendentes <b-badge variant="info">
          {{ getQuantity }}
        </b-badge> | Valor em transferências pendentes <b-badge variant="info">
          {{ setPriceFormat($t('payments.labels.currency'), getAmount) }}
        </b-badge>
      </p>

      <b-button variant="primary" class="select-all-btn" @click="selectAllTeds">
        {{ $t('payments.manageTed.selectAll') }}
      </b-button>
      <b-button
        variant="success"
        class="select-all-btn"
        :disabled="selectedTeds.length === 0"
        @click="confirmBulkTed"
      >
        {{ $t('payments.manageTed.approveSelected') }}
      </b-button>

      <div class="table-pending-credit-request-list">
        <custom-data-table
          ref="ted_table"
          :fields="fields"
          :filters="filters"
          :per-page="10"
          :query="transfer_by_status"
          :query-variables="{ status: 'pending' }"
          :query-node="'getTransferByStatus'"
        >
          <span slot="select" slot-scope="props">
            <b-form-checkbox
              :checked="isSelected(props.rowData.id)"
              @input="selectTed($event, props.rowData)"
            />
          </span>
          <span slot="driver" slot-scope="props">
            <router-link
              v-b-tooltip
              :title="$t('payments.labels.goToDriver')"
              :to="`/drivers/profile/${props.rowData.driver.id}`"
            >
              {{ props.rowData.driver.name }}
              <i class="fa fa-external-link icon-size-driver" />
            </router-link>
          </span>
          <span slot="created_at" slot-scope="props">
            {{ formatDateUtc(props.rowData.created_at) }}
          </span>
          <span slot="bankAccount" slot-scope="props">
            {{ setBankAccount(props.rowData.bank_account) }}
          </span>
          <span slot="amount" slot-scope="props">
            {{ setPriceFormat($t('payments.labels.currency'), props.rowData.amount) }}
          </span>
          <span slot="actions" slot-scope="props">
            <b-button variant="primary" size="sm" @click="goTo(props.rowData)">{{ $t('payments.manageTed.modalTitle') }}</b-button>
          </span>
        </custom-data-table>
        <modal-manage-ted
          v-model="modalCreditRequest"
          :loading="loading"
          :modal_data="modalData"
          @approve="approveCredit"
          @reprove="reproveCredit"
        />
        <modal-bulk-ted-status
          v-model="modalBulkTedStatus"
          :modal_data="modalBulkTedData"
          @change="handleCloseBulkTedModal"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import { formatPrice } from '@utils/numbers';
import CustomDataTable from '@components/CustomDataTable';
import TRANSFER_BY_STATUS from '@graphql/ted/queries/transfer-by-status.gql';
import CONSOLIDATED_TRANSFERS from '@graphql/ted/queries/consolidated-transfers.gql';
import APPROVE_BULK_TED from '@graphql/ted/mutations/approve-bulk-ted.gql';
import APPROVE_TED from '@graphql/ted/mutations/approve-ted.gql';
import REPROVE_TED from '@graphql/ted/mutations/reprove-ted.gql';
import { mapGetters } from 'vuex';
import ModalManageTed from '@components/modals/manage-ted';
import ModalBulkTedStatus from '@components/modals/bulk-ted-status';
import { makeMutation, makeQuery } from "@graphql/middleware";

export default {
  name: 'ManageTed',
  components: {
    Layout,
    CustomDataTable,
    ModalManageTed,
    ModalBulkTedStatus,
    Loader: () => import('@components/shared/loader')
  },
  props: {
    fieldsAllow: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      modalData: {},
      count: 0,
      loader: false,
      loading: false,
      modalBulkTedData: [],
      selectedTeds: [],
      modalCreditRequest: false,
      modalBulkTedStatus: false,
      kovi_fatura: process.env.KOVI_FATURA_URL,
      transfer_by_status: TRANSFER_BY_STATUS,
      consolidated_transfers: {amount: 0, quantity: 0},
      status: 'ACTIVE',
      fields: [
        {
          name: 'select',
          title: ''
        },
        {
          name: 'driver',
          title: this.$t('payments.manageTed.table.driverName')
        },
        {
          name: 'bankAccount',
          title: this.$t('payments.manageTed.table.account')
        },
        {
          name: 'amount',
          title: this.$t('payments.manageTed.table.amount'),
        },
        {
          name: 'created_at',
          title: this.$t('payments.manageTed.table.created_at')
        },
        {
          name: 'actions',
          title: 'Ações'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('user', ['attributes', 'findGroups']),
    filters() {
      return {}
    },
    getQuantity() {
      return (this.selectedTeds.length === 0) ? this.consolidated_transfers.quantity : this.selectedTeds.length
    },
    getAmount() {
      return (this.selectedTeds.length === 0) ? this.consolidated_transfers.amount : this.selectedTeds.reduce((a, b) => a + b.amount, 0)
    }
  },
  mounted() {
    this.getConsolidatedTransfers();
  },
  methods: {
    getConsolidatedTransfers() {
      makeQuery(
        CONSOLIDATED_TRANSFERS
      )
      .then((result) => {
        this.consolidated_transfers = result.data.getConsolidatedTransfers;
      });
    },
    handleCloseBulkTedModal(e) {
      if(!e) {
        this.$refs.ted_table.$_refresh();
        this.selectedTeds = [];
        this.getConsolidatedTransfers();
      }
    },
    confirmBulkTed() {
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      })

      confirmationWindow({
        title: 'Tem certeza?',
        text: 'Você irá aprovar todas as TEDs selecionadas.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        input: 'text',
        inputValue: '',
        inputPlaceholder: 'Insira o motivo da aprovação',
        inputValidator: value => {
          return !value && 'Sorry! Você precisa digitar algum motivo!';
        },
        inputAttributes: {
          maxlength: 250,
          autocomplete: 'off',
        },
      }).then(async result => {
        if(!result || result.dismiss === 'cancel') return;

        const teds = this.selectedTeds.map((ted) => {
          return {
            wire_transfers_id: ted.id,
            additional_data: {
              analysed_by: this.$root.$store.state.user.attributes.email,
              reason: result.result
            }
          }
        })

        const items = { input: { items: teds } }

        makeMutation(
          APPROVE_BULK_TED,
          items
        )
          .then((data) => {
            this.loader = false
            const response = data.data.approveBulkTransfers
            const tableData = this.$refs.ted_table.tableData;

            const wireTransfers = response.map((wireTransfer) => {
              const transfer = tableData.find(item => item.id === wireTransfer.wire_transfers_id);
              return {
                driver: transfer.driver,
                amount: transfer.amount,
                success: wireTransfer.success,
                error: this.$t(`payments.manageTed.errors.${wireTransfer.error}`)
              }
            })

            this.modalBulkTedData = Object.assign([], wireTransfers);
            this.modalBulkTedStatus = true;
          })
          .catch((error) => {
            this.loader = false
            const status_code = (error.message) ? error.status_code : null
            this.showErrorModal(status_code);
          });

      })
    },
    selectAllTeds() {
      if(this.selectedTeds.length === this.$refs.ted_table.data.count) {
        this.selectedTeds = [];
        return;
      }
      const teds = this.$refs.ted_table.data.items;
      const ids = teds.map((ted) => ({id: ted.id, amount: ted.amount}));
      this.selectedTeds = Object.assign([], ids);
    },
    isSelected(id) {
      return this.selectedTeds.some(ted => ted.id === id)
    },
    selectTed(event, ted) {
      const id = ted.id
      if(event && this.isSelected(id)) return;
      if(event && !this.isSelected(id)) this.selectedTeds.push({id, amount: ted.amount});
      if(!event && this.isSelected(id)) {
        const i = this.selectedTeds.map((ted) => ted.id).indexOf(id);
        this.selectedTeds.splice(i, 1);
      }
    },
    formatDateUtc (date) {
      return this.$moment.utc(date).format('DD/MM/YYYY')
    },
    setBankAccount (bankAccount) {
      return `${bankAccount.bank_name} - Ag: ${bankAccount.branch} / Conta: ${bankAccount.number}`
    },
    showErrorModal(code) {
      this.$swal({
        type: 'error',
        title: this.$t('payments.creditRequest.modalErrorTitle'),
        text: (code) ? this.$t(`payments.manageTed.errors.${code}`) : this.$t('payments.creditRequest.modalErrorText'),
        showConfirmButton: false,
        timer: 2500,
      }).then(() => console.log(true));
    },

    showSuccessModal() {
      this.$swal({
        type: 'success',
        title: this.$t('payments.creditRequest.modalSuccessTitle'),
        text: this.$t('payments.creditRequest.modalSuccessText'),
        showConfirmButton: false,
        timer: 2500,
      }).then(() => console.log(true));
    },

    approveCredit(data) {
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });

      confirmationWindow({
        title: this.$t('payments.creditRequest.modalConfirmationTitle'),
        text: this.$t('payments.creditRequest.modalConfirmationText'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('payments.buttons.confirm'),
        cancelButtonText: this.$t('payments.buttons.cancel'),
        showLoaderOnConfirm: true,
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.loader = true

          makeMutation(
            APPROVE_TED,
            {
              transferId: data.id,
              requested_by: this.attributes.email,
              reason: 'approve'
            }
          )
          .then((data) => {
              this.loader = false

              const response = data.data.approveTedRequest;
              if(response.code !== 200) throw response.error;

              this.modalCreditRequest = false;
              this.showSuccessModal();
              this.$refs.ted_table.$_refresh();
              this.loading = false;
            })
            .catch((error) => {
              this.loader = false
              this.showErrorModal(error);
              this.loading = false;
            });
        }
      });
    },

    reproveCredit(data) {
      this.$swal.fire({
        title: this.$t('payments.creditRequest.modalConfirmationTitle'),
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
        text: this.$t('payments.creditRequest.modalReproveCreditText'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('payments.buttons.confirm'),
        cancelButtonText: this.$t('payments.buttons.cancel'),
        reverseButtons: true,
        input: 'select',
        inputValue: '',
        inputOptions: {
          "Transações pendentes de abatimento pela caução": "Transações pendentes de abatimento pela caução",
          "Não foi cobrada multa rescisória":"Não foi cobrada multa rescisória",
          "Solicitação de devolução em prazo < 15 dias": "Solicitação de devolução em prazo < 15 dias",
          "Solicitação de devolução em prazo > 15 dias < 60 dias": "Solicitação de devolução em prazo > 15 dias < 60 dias",
          "Devolução via gateway de pagamento": "Devolução via gateway de pagamento",
          "Dados bancários inválidos":"Dados bancários inválidos",
          "Driver ativo":"Driver ativo",
          "Solicitação em duplicidade":"Solicitação em duplicidade",
          "Outros":"Outros",
        },
        inputPlaceholder: this.$t('payments.creditRequest.modalReproveCreditInputPlaceholder'),
        inputValidator: value => !value && this.$t('payments.creditRequest.modalReproveCreditInputValidator'),
        inputAttributes: {
          maxlength: 250,
          autocomplete: 'off',
        },
      }).then(result => {
        if(!result || result.dismiss === 'cancel') return;

        if (result.value) {
          this.loader = true
          makeMutation(
            REPROVE_TED,
            {
              transferId: data.id,
              reason: result.value,
              analysed_by: this.attributes.email,
            }
          )
          .then(() => {
            this.loader = false
            this.modalCreditRequest = false
            this.showSuccessModal();
            this.$refs.ted_table.$_refresh();
          })
          .catch(() => {
            this.loader = false
            this.showErrorModal();
          });
        }
      });
    },
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
    goTo(data) {
      if (this.findGroups('ACL:TED:TO_APPROVE')) {
        this.modalData = data
        this.modalCreditRequest = true
      }
    },
  },
};
</script>

<style scoped>
  .ted-data {
    margin-top: -24px;
    margin-bottom: 40px;
  }

  .select-all-btn {
    margin-bottom: 10px;
    margin-right: 5px;
  }
  .custom-data-table__pagination {
    margin-bottom: 40px;
  }
  .icon-size-driver {
    width: 12px;
    text-align: center;
  }
</style>
